import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpGenericService<T> {
  constructor(
    private http: HttpClient
  ) { }

  urlBackend(url: string) {
    return environment.backend + url;
  }

  get(url: string, options = {}) {
    return this.http.get(this.urlBackend(url), options);
  }

  post(url: string, body: T, options = {}) {
    return this.http.post(this.urlBackend(url), body, options);
  }

  put(url: string, body: T, options = {}) {
    return this.http.put(this.urlBackend(url), body, options);
  }

  delete(url: string, options = {}) {
    return this.http.delete(this.urlBackend(url), options);
  }
}
