import { Component } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  template: `
  <div class="overlay" *ngIf="isLoading$ | async">
    <div class="loadingio-eclipse">
      <div class="ldio-rpinwye8j0b">
        <div></div>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  isLoading$ = this._spinner.isloading$;

  constructor(private _spinner: SpinnerService) { }

}
