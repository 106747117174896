import { EventEmitter, Injectable } from '@angular/core';
import { LoadingController, Platform, ToastController, ToastOptions } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private loaderEventEmmiter = new EventEmitter<string>();
  private loaderEventClose = new EventEmitter<boolean>();
  private loader!: HTMLIonLoadingElement;
  constructor(
    private toastController: ToastController,
    private platform: Platform,
    private domSanitizer: DomSanitizer,
    private loadingController: LoadingController) {
    this.loaderEvent();
  }




  //<!-- ============================================================== -->
  //<!-- Mostrar Mensaje Ionic | Hay que pasarle un Objeto con los siguientes campos: { message, duration } -->
  //<!-- ============================================================== -->
  public async generateToast(options: ToastOptions) {
    const toast = await this.toastController.create({ ...options, mode: 'ios' });
    await toast.present();
  }




  public async convertUrlToBinary(url, name) {
    const response = await fetch(url);
    const data = await response.blob();
    //console.log(new File([data], name,{ type: data.type || 'image/'+name.split('.')[1] }));
    return new File([data], name, { type: data.type || 'image/' + name.split('.')[1] });
  }




  safeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url.replace('watch?v=', 'embed/'));
  }




  public loaderDismiss() {
    // return this.loaderEventClose.next(true);
  }




  //<!-- ============================================================== -->
  //<!-- Mostrar Spiner de Carga -->
  //<!-- ============================================================== -->
  public loading(value = 'Procesando') {
    this.loaderEventEmmiter.next(value);
  }




  private loaderEvent() {
    this.loaderEventEmmiter.subscribe(async (resp) => {
      this.loader = await this.loadingController.create({
        spinner: 'circles',
        message: resp,
        mode: 'ios'
      });
      this.loaderEventClose.subscribe(setTimeout(async () => {
        if (this.loader) {
          await this.loadingController.dismiss();
        }
      }, 2000));
      await this.loader.present();
    });
  }



}
