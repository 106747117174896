import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CapacitacionCreatorComponent } from './capacitacion-creator/capacitacion-creator.component';
import { SessionGuard } from './FAST-TRACK-FRONTEND/guards/session.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home/programas',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [SessionGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'visualizador/:id',
    loadChildren: () => import('./visualizador/visualizador.module').then(m => m.VisualizadorPageModule),
    canActivate: [SessionGuard]
  },
  {
    path: 'capacitacion-creator',
    loadChildren: () => import('./capacitacion-creator/capacitacion.module').then(m => m.CapacitacionModule),
    canActivate: [SessionGuard]
  },
  {
    path: 'capacitacion-editor/:id',
    loadChildren: () => import('./capacitacion-editor/capacitacion-editor.module').then(m => m.CapacitacionEditorModule),
    canActivate: [SessionGuard]
  },
  {
    path: 'plan-creator',
    loadChildren: () => import('./home/pages/plan-creator/plan-creator.module').then(m => m.PlanCreatorPageModule),
    canActivate: [SessionGuard]
  }

];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
