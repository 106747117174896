import {Injectable} from '@angular/core';
import {HttpGenericService} from './http-generic.service';
import {UtilsService} from './utils.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private data = new Subject();
  private userStore = sessionStorage.getItem('US_LOGIN');
  private tokenStore = sessionStorage.getItem('TOK_LOGIN');

  private userLogged = new BehaviorSubject(this.userStore !== null ? JSON.parse(this.userStore) : undefined);
  private userToken = new BehaviorSubject(this.tokenStore !== null ? this.tokenStore : undefined);

  constructor(
    private http: HttpGenericService<any>,
    private utils: UtilsService,
    private router: Router
  ) {
    this.login();
    this.userToken.subscribe(resp => {
     if(resp) {
       sessionStorage.setItem('TOK_LOGIN', resp);
     }
    });
    this.userLogged.subscribe(resp => {
      if(resp) {
        sessionStorage.setItem('US_LOGIN', JSON.stringify(resp));
      }
    });
  }

  inSession() {
    return this.userLogged.value && this.userToken.value;
  }

  up(data: any) {
    this.data.next(data);
  }

  private login() {
    this.data.pipe(
      debounceTime(500)
    ).subscribe(resp => {
      this.http.post('/auth/login', resp).subscribe((req: any) => {
        let user: any;
        let token: any;
        if(req.hasOwnProperty('User') || req.hasOwnProperty('user')) {
          user = req.User || req.user;
          this.userLogged.next(req.User || req.user);
        }
        if(req.hasOwnProperty('Token') || req.hasOwnProperty('token')){
          token = req.Token || req.token;
          this.userToken.next(req.Token || req.token);
        }
        if(user && token) {
          this.router.navigateByUrl('').then();
        }
      },async err => {
        await this.utils.generateToast({ message: 'Verifica tus credenciales', duration: 3000, color: 'warning'});
      });
    });
  }
}
